export const beaconAssignmentOptionsArray = [
  { value: 'all', label: 'All', default: true, order: 0 },
  { value: 'assigned', label: 'Asset assigned', default: false, order: 1 },
  { value: 'unassigned', label: 'Asset not assigned', default: false, order: 2 },
];

export const menuOptionsAssigned = [
  { value: 'entity_id', label: 'Asset ID' },
  { value: 'device_id', label: 'Beacon ID' },
  { value: 'source', label: 'Source' },
];

export const menuOptionsAssetNotAssigned = [
  { value: 'device_id', label: 'Beacon ID' },
  { value: 'source', label: 'Source' },
];
