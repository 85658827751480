import React from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';

const LoadingBarsStyled: any = styled.div`
  margin: 0 auto;

  .bar {
    height: ${(props: any) => props.barHeight}px;
    width: ${(props: any) => props.barWidth}px;
    background: ${colours.inform};
    display: inline-block;
    animation: pulse 1.2s infinite ease-out;
    margin: 0 2px;
    &: nth-of-type(1) {
      animation-delay: 0s;
    }
    &: nth-of-type(0n+2) {
      animation-delay: 0.2s;
    }
    &: nth-of-type(0n+3) {
      animation-delay: 0.4s;
    }
    &: nth-of-type(0n+4) {
      animation-delay: 0.6s;
    }
    &: nth-of-type(0n+5) {
      animation-delay: 0.8s;
    }
  }
  @keyframes pulse {
    0%,
    75% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 3);
    }
  }
`;
type LoadingBarsType = {
  barHeight?: number;
  barWidth?: number;
};
export default function LoadingBars({ barHeight = 20, barWidth = 10 }: LoadingBarsType) {
  return (
    <LoadingBarsStyled barHeight={barHeight} barWidth={barWidth}>
      <div className="loading-bars">
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </LoadingBarsStyled>
  );
}
