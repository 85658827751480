import React from 'react';
import { NoSearchResultsPanelStyledDiv } from '../Styled';
import errorImg from '../icons/error.svg';

export default function NoSearchResultsPanel({ searchTerm }: { searchTerm: string }) {
  return (
    <NoSearchResultsPanelStyledDiv>
      <img src={errorImg} alt="error" />
      <span>Sorry, no assets were found for {searchTerm}</span>
    </NoSearchResultsPanelStyledDiv>
  );
}
