import React from 'react';
import { components } from 'react-select';
type menuOptionsType = { value: string; label: string };

const OptionMenu: React.FC = (props: any) => {
  const { searchTerm, menuOptions, handleClickOptionSearch } = props;

  return (
    <div className="asyncSearch__menu-option-container">
      <div className="dropdownMessage">DO YOU WANT TO SEARCH IN:</div>
      {menuOptions.map((option: menuOptionsType) => {
        return (
          <div
            role="button"
            tabIndex={0}
            key={option.value}
            onKeyDown={() => handleClickOptionSearch(option)}
            onClick={() => handleClickOptionSearch(option)}
          >
            <components.Option {...props}>
              <b>{option.label}</b> for: {searchTerm}
            </components.Option>
          </div>
        );
      })}
    </div>
  );
};

export default OptionMenu;
