import { format, formatDistanceToNowStrict } from 'date-fns';
import React from 'react';
import { colours } from 'styling/colours';
import { replaceWordsInDistanceString } from 'utils/utils';

const LastSeenReadoutSpan = ({ timestamp }: { timestamp: string }) => {
  if (!timestamp) return <span>--</span>;

  const distance = formatDistanceToNowStrict(new Date(timestamp), { roundingMethod: 'floor' });

  if (distance.match(/hour|day|week|month|year/)) {
    return (
      <span style={{ display: 'flex' }}>
        <p style={{ color: `${colours.error}`, paddingRight: '10px' }}>
          {replaceWordsInDistanceString(distance)} ago
        </p>
        ({format(parseInt(timestamp, 10), 'dd/MM/yyyy - HH:mm')})
      </span>
    );
  }
  return <span>{replaceWordsInDistanceString(distance)} ago</span>;
};

export default LastSeenReadoutSpan;
